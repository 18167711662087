<template>
  <div class="flex justify-center">
    <a
      v-if="settings.apple_store && deviceIsIos"
      class="mx-1"
      :href="settings.apple_store"
      target="_blank"
      rel="noopener noreferrer"
    >
      <v-icon :size="30">
        <IconsAppleStore />
      </v-icon>
    </a>
    <a
      v-if="settings.google_play&&deviceIsAndriod"
      class="mx-1"
      :href="settings.google_play"
      target="_blank"
      rel="noopener noreferrer"
    >
      <v-icon :size="30">
        <IconsGooglePlay />
      </v-icon>
    </a>
    <div
      v-if="settings.direct_download&&deviceIsAndriod"
      class="bg-gray-500 hover:bg-gray-700 text-xs text-white font-bold p-1 mx-1 items-center rounded flex justify-center"
    >
      <a

        :href="settings.direct_download"
        target="_blank"
        rel="noopener noreferrer"
        class=""
      >
        {{ t('download_directly') }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
const { settings } = useSettings()
const { t } = useI18n()

withDefaults(
  defineProps<{
    deviceIsAndriod?: boolean
    deviceIsIos?: boolean
  }>(),
  {
    deviceIsAndriod: true,
    deviceIsIos: true
  }
)
</script>
